;
var app = app || {};

$(function() {
    
    $('.reel-carousel').each(function() {
        var elem = $(this)[0];
        elem.swiper = new Swiper(elem, {
            effect: 'slide',
            direction: 'horizontal',
            centeredSlides: true,
            loop: true,
            slidesPerView: 5,
            loopAdditionalSlides: 4,
            // scrollbar: {
            //     el: '.swiper-scrollbar',
            //     draggable: true,
            //     dragSize: 'auto'
            // },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                // when window width is <= 576px
                576: {
                    slidesPerView: 1
                },
                // when window width is <= 768px
                768: {
                    slidesPerView: 2
                },
                992: {
                    slidesPerView: 3
                }
            }
        });
    });    
});